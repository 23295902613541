import React from 'react'

import { StyledTableCell, StyledTableRow } from '../../../components/EnchancedTable'
import { OAuthClient } from '../../../types'
import EnabledSwtich from '../../../components/EnabledSwtich'

interface Props {
  client: OAuthClient
  onClick: (client: OAuthClient) => void
}

const OAuthClientRow: React.FC<Props> = ({ client, onClick }) => {
  const handleClick = () => {
    onClick(client)
  }

  return (
    <StyledTableRow hover role="checkbox" tabIndex={-1} key={`view_row_${client.clientId}`} onClick={handleClick}>
      <StyledTableCell align="left">{client.name}</StyledTableCell>
      <StyledTableCell align="right">{client.clientId}</StyledTableCell>
      <StyledTableCell align="right">{client.redirectUris}</StyledTableCell>
      <StyledTableCell align="right">{client.allowedAudiences}</StyledTableCell>
      <StyledTableCell align="right">
        {<EnabledSwtich enabled={!client.disabled} editable={false} onChange={() => {}} />}
      </StyledTableCell>
    </StyledTableRow>
  )
}

export default OAuthClientRow
