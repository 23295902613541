export enum Application {
  INSIGHT = 'INSIGHT',
  HADES = 'HADES',
  CALMASTER = 'CALMASTER',
  JARVIS = 'JARVIS',
  CORE = 'CORE',
  HAPPY = 'HAPPY',
  ANA = 'ANA',
  SHIFT_PLANNER = 'SHIFT_PLANNER',
  TODO_LIST = 'TODO_LIST',
  CLAIRE = 'CLAIRE',
}

export enum View {
  MANAGEMENT_REPORT = 'MANAGEMENT_REPORT',
  COST_PLAN_REPORT = 'COST_PLAN_REPORT',
  PERSONNEL_REPORT = 'PERSONNEL_REPORT',
  DATA_ISSUES = 'DATA_ISSUES',

  ROLE_MANAGEMENT = 'ROLE_MANAGEMENT',
  USER_MANAGEMENT = 'USER_MANAGEMENT',
}

export enum Action {
  READ = 'READ',
  WRITE = 'WRITE',
  READ_WRITE = 'READ_WRITE',
}

export enum Level {
  ALL = 'ALL',
  OWN = 'OWN',
  SAME_CLINIC = 'SAME_CLINIC',
  SAME_LEVEL = 'SAME_LEVEL',
  SAME_TENANT = 'TENANT_ADMIN',
}

export interface APIError {
  message: string
  error: number
}

export interface ApplicationPermission {
  application: Application
  view: string
}

export interface Permission {
  action: Action
  resource: string
  shortName: string
}

export interface DataPermission {
  level: Level
  permission: Permission
}

export interface Clinic {
  referenceId: string
  name: string
  tenant: string
}

export interface Practitioner {
  referenceId: string
  name: string
  lockUser: boolean
  clinic: Clinic
}

export interface Role {
  referenceId: string
  name: string
  applicationPermissions: ApplicationPermission[]
  dataPermissions: DataPermission[]
}

export interface RoleForClinics {
  role: Role
  clinics: Clinic[]
}

export interface User {
  referenceId: string
  name: string
  email: string
  imgUrl: string | null
  tenant: string | null
  practitioners: Practitioner[]
  roles: RoleForClinics[]
  disabled: boolean
}

export enum RevisionType {
  ADD = 'ADD',
  MOD = 'MOD',
  DEL = 'DEL',
}

export interface Revision {
  rev: number
  createdAt: string
  createdBy: string
  type: RevisionType
  changes: string[]
}

export interface UserChange {
  state: User
  revision: Revision
}

export type Order = 'asc' | 'desc'

export enum AuthenticationType {
  PASSWORD = 'password',
  GOOGLE = 'google',
}

export interface ApiKey {
  referenceId: string
  key: string
  enabled: boolean
  createdBy: string
  createdAt: string
  modifiedBy: string
  modifiedAt: string
}

export interface ApiKeyFull {
  referenceId: string
  key: string
  secret: string
  enabled: boolean
  createdBy: string
  createdAt: string
}
export interface SystemUser {
  referenceId: string
  name: string
  tenant: Tenant | null
  role: Role | null
  clinics: Clinic[]
  apiKeys: ApiKey[]
}

export interface Tenant {
  referenceId: string
  name: string
  internal: boolean
  deprecated: boolean
  subscribedFor: string[]
}

export interface OAuthClient {
  clientId: string
  clientSecret: string | null
  name: string
  redirectUris: Set<string>
  idTokenSignedResponseAlg: string | null
  allowedAudiences: Set<string>
  defaultAudience: string | null
  disabled: boolean
  user: SystemUser | null
}

export interface Page<T> {
  content: T[]
  totalElements: number
  size: number
  number: number
}

export const AVAILABLE_SUBSCRIPTIONS = [
  'ANA',
  'AVAILY',
  'JOBS',
  'CALMASTER',
  'CLAIRE',
  'HAPPY',
  'HEALTH_ASSISTANT',
  'MEDLOG',
  'MEDPRESS',
]
