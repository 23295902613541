import { Box, SvgIcon, SvgIconProps } from '@mui/material'
import React, { forwardRef } from 'react'

const OAuthIcon = forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
  return (
    <SvgIcon {...props} ref={ref} viewBox="0 0 24 24">
      <path
        d="M12 2L4 5.4v5.1c0 5.1 3.4 9.8 8 11.5 4.6-1.7 8-6.4 8-11.5V5.4L12 2z"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linejoin="round"
      />

      <circle cx="12" cy="12" r="3.5" fill="none" stroke="currentColor" stroke-width="2" />

      <path d="M8 12h8" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
    </SvgIcon>
  )
})

OAuthIcon.displayName = 'OAuthIcon'

export default OAuthIcon
