import React, { useCallback, useEffect, useMemo, useState } from 'react'

import {
  Alert,
  AlertTitle,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import RefreshButton from '../../components/RefreshButton'
import { OAuthClient, Order } from '../../types'
import { createOAuthClient, fetchOAuthClients, updateOAuthClient } from '../../api/API'
import { useDebounce } from 'use-debounce/lib'
import { EnhancedTableHead } from '../../components/EnchancedTable'
import { getErrorMessage } from '../../utils/AxiosUtils'
import OAuthClientRow from './components/OAuthClientRow'
import OAuthCreator, { CreateOAuthClientCmd } from './components/OAuthClientCreator'
import OAuthClientCreateSuccess from './components/OAuthClientCreateSuccess'
import OAuthClientEditor, { UpdateOAuthClientCmd } from './components/OAuthClientEditor'

const columns = [
  { key: 'name', label: 'Name', width: '15%' },
  { key: 'clientId', label: 'Client ID', width: '25%' },
  { key: 'redirectUrls', label: 'Redirect URLs', width: '25%' },
  { key: 'allowedAudiences', label: 'Allowed audiences', width: '25%' },
  { key: 'active', label: 'Enabled', width: '10%' },
]

const OAuth: React.FC = () => {
  const [clients, setClients] = useState<OAuthClient[]>([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [order] = useState<Order>('asc')
  const [orderBy] = useState<string>('name')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(100)
  const [count, setCount] = useState(0)
  const [showCreateClient, setShowCreateClient] = useState(false)
  const [creatingClient, setCreatingClient] = useState(false)
  const [creatingClientError, setCreatingClientError] = useState<string | null>(null)
  const [clientSecret, setClientSecret] = useState<string>('')

  const [clientUpdating, setClientUpdating] = useState<OAuthClient | null>(null)
  const [updatingClient, setUpdatingClient] = useState(false)
  const [updatingClientError, setUpdatingClientError] = useState<string | null>(null)

  const [nameFilter, setNameFilter] = useState<string>('')
  const [nameFilterDebounced] = useDebounce(nameFilter, 750, { leading: false })

  const fetchClientsCallback = useCallback(() => {
    if (fetchOAuthClients) {
      setLoading(true)
      fetchOAuthClients(page, rowsPerPage, 'name', nameFilterDebounced)
        .then((result) => {
          setClients(result.content)
          setCount(result.totalElements)
          setError(null)
        })
        .catch((error) => setError(error.message))
        .finally(() => {
          setLoading(false)
        })
    }

    //we don't want to include api in the dependencies
    //eslint-disable-next-line
  }, [page, rowsPerPage, nameFilterDebounced])

  useEffect(fetchClientsCallback, [fetchClientsCallback])

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleNameFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNameFilter(event.target.value)
  }

  const handleClientCreateDiscard = () => {
    setCreatingClientError(null)
    setShowCreateClient(false)
  }

  const handleClientCreate = (cmd: CreateOAuthClientCmd) => {
    setCreatingClient(true)
    createOAuthClient(
      cmd.name,
      cmd.redirectUris,
      cmd.idTokenSignedResponseAlg,
      cmd.allowedAudiences,
      cmd.defaultAudience
    )
      .then((result) => {
        setShowCreateClient(false)
        setClientSecret(result.clientSecret as string)
        fetchClientsCallback()
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error)
        setCreatingClientError(errorMessage)
      })
      .finally(() => {
        setCreatingClient(false)
      })
  }

  const handleClientUpdate = (cmd: UpdateOAuthClientCmd) => {
    setUpdatingClient(true)
    updateOAuthClient(
      cmd.clientId,
      cmd.name,
      cmd.redirectUris,
      cmd.idTokenSignedResponseAlg,
      cmd.allowedAudiences,
      cmd.defaultAudience,
      cmd.systemUserReferenceId
    )
      .then((result) => {
        fetchClientsCallback()
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error)
        setUpdatingClientError(errorMessage)
      })
      .finally(() => {
        setClientUpdating(null)
        setUpdatingClient(false)
      })
  }

  const handleTenantUpdateDiscard = () => {
    setClientUpdating(null)
  }

  const handleOnClick = (client: OAuthClient) => {
    setClientUpdating(client)
  }

  const rows = useMemo(
    () =>
      clients.map((client) => (
        <OAuthClientRow key={`tenant${client.clientId}`} client={client} onClick={handleOnClick} />
      )),
    //no need to include handleUserEdit as a dependency, just makes everything slow
    //eslint-disable-next-line
    [clients]
  )

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <Typography variant="h4" color="primary" gutterBottom={false}>
            {'OAuth clients'}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <TextField
                label={'Filter by name'}
                variant="outlined"
                value={nameFilter}
                onChange={handleNameFilterChange}
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={2}>
          {useMediaQuery((theme: Theme) => theme.breakpoints.up('sm')) && (
            <Grid container justifyContent="flex-end">
              <Button color="primary" size="large" onClick={() => setShowCreateClient(true)}>
                <AddIcon fontSize="large" />
                {'Create'}
              </Button>
              <RefreshButton loading={loading} refresh={fetchClientsCallback} />
            </Grid>
          )}
        </Grid>
        <Grid item xs={12}>
          {error ? (
            <Alert severity="error">
              <AlertTitle>Error fetching Users</AlertTitle>
              {error}
            </Alert>
          ) : (
            <Paper>
              <TableContainer>
                <Table size="medium">
                  <EnhancedTableHead
                    columns={columns}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={() => {}}
                    rowCount={clients.length}
                    leadColspan={0}
                  />
                  <TableBody>
                    <colgroup>
                      {columns.map((column) => (
                        <col key={`colgroup_${column.key}`} width={column.width} />
                      ))}
                    </colgroup>
                    {rows}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[100, 200, 500]}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          )}
        </Grid>
      </Grid>
      <OAuthCreator
        open={showCreateClient}
        loading={creatingClient}
        error={creatingClientError}
        onCancel={handleClientCreateDiscard}
        onCreate={handleClientCreate}
      />
      <OAuthClientCreateSuccess
        open={!!clientSecret}
        clientSecret={clientSecret}
        onClose={() => {
          setClientSecret('')
        }}
      />
      <OAuthClientEditor
        open={!!clientUpdating}
        client={clientUpdating}
        loading={updatingClient}
        error={updatingClientError}
        onUpdate={handleClientUpdate}
        onCancel={handleTenantUpdateDiscard}
      />
    </div>
  )
}

export default OAuth
