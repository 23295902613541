import axios from 'axios'
import { AuthenticationType, OAuthClient, Page, Practitioner, Role, Tenant } from '../types'
import { addAuthInterceptors } from '@dentalux/security'
import { Clinic } from '@dentalux/security/lib/types'

const hadesClient = axios.create({
  baseURL: process.env.REACT_APP_HADES_URI,
  withCredentials: true,
})

addAuthInterceptors(hadesClient)

export const fetchUsers = async (
  page: number,
  size: number,
  sortBy: string,
  nameFilter: string,
  emailFilter: string,
  clinicsFilter: Clinic[],
  roleFilter: Role[],
  disabledFilter?: boolean
) => {
  const clinicReferenceIdsFilter = clinicsFilter.map((clinic) => clinic.referenceId).join(',')
  const roleReferenceIdsFilter = roleFilter.map((clinic) => clinic.referenceId).join(',')
  const result = await hadesClient.get(`/api/users`, {
    params: {
      page,
      size,
      sortBy,
      nameFilter,
      emailFilter,
      clinicReferenceIdsFilter,
      roleReferenceIdsFilter,
      disabledFilter,
    },
  })

  return result
}

export const fetchUserChanges = async (
  page: number,
  size: number,
  sortBy: string,
  nameFilter: string,
  emailFilter: string
) => {
  const result = await hadesClient.get(`/api/users/changes`, {
    params: {
      page,
      size,
      sortBy,
      nameFilter,
      emailFilter,
    },
  })

  return result
}

export const fetchSystemUsers = async (
  page: number,
  size: number,
  sortBy: string,
  nameFilter: string,
  clinicsFilter: Clinic[],
  roleFilter: Role[]
) => {
  const clinicReferenceIdsFilter = clinicsFilter.map((clinic) => clinic.referenceId).join(',')
  const roleReferenceIdsFilter = roleFilter.map((clinic) => clinic.referenceId).join(',')
  const result = await hadesClient.get(`/api/system-users`, {
    params: {
      page,
      size,
      sortBy,
      nameFilter,
      roleFilter: roleReferenceIdsFilter,
      clinicFilter: clinicReferenceIdsFilter,
    },
  })

  return result
}

export const editUser = async (
  userId: string,
  rolesForClinics: { [key: string]: string[] } | null,
  tenant: string | null,
  disabled: boolean | null,
  practitioners: string[] | null,
  lockPractitionerMappings: boolean | null
) => {
  if (!rolesForClinics && !tenant) {
    throw Error('Either roleId or tennant must be provided to perform a change')
  }

  const result = await hadesClient.patch(`/api/users/${userId}`, {
    rolesToClinics: rolesForClinics,
    tenant,
    disabled,
    practitioners,
    lockPractitionerMappings,
  })

  return result
}

export const generateToken = async (userId: string): Promise<string> => {
  const result = await hadesClient.post(`/api/users/${userId}/token`)

  return result.data.token
}

export const createUser = async (
  name: string,
  email: string,
  authenticationType: string,
  tenant: string | null,
  password: string | undefined
) => {
  const subpath =
    authenticationType === AuthenticationType.PASSWORD ? authenticationType : `oauth/${authenticationType}`
  const result = await hadesClient.post(`/api/users/${subpath}`, {
    name,
    email,
    password,
    tenant,
  })

  return result
}
export const fetchRoles = async (page: number, size: number, sortBy: string, type?: string[]) => {
  const result = await hadesClient.get(`/api/roles`, {
    params: {
      page,
      size,
      sortBy,
      type: type?.join(','),
    },
  })

  return result.data
}
export const fetchTenantsNames = async () => {
  const result = await hadesClient.get(`/api/tenants`, {
    params: {},
  })

  return result.data
}
export const fetchClinics = async () => {
  const result = await hadesClient.get(`/api/clinics`, {
    params: {},
  })

  return result.data
}
export const createSystemUser = (name: string, tenantReferenceId: string) =>
  hadesClient.post(`/api/system-users`, {
    name,
    tenantReferenceId,
  })

export const editSystemUser = async (
  userId: string,
  roleId: string | null,
  tenantId: string | null,
  clinics: string[] | null
) => {
  if (!roleId || !clinics) {
    throw Error('Either roleId or clinic must be provided to perform a change')
  }
  const result = await hadesClient.patch(`/api/system-users/${userId}`, {
    roleReferenceId: roleId,
    tenantReferenceId: tenantId,
    clinicReferenceIds: clinics,
  })

  return result
}

export const createApiKey = async (userId: string) => {
  const result = await hadesClient.post(`/api/system-users/${userId}/api-keys`)

  return result
}

export const editApiKey = async (apiKeyId: string, enabled: boolean) => {
  const result = await hadesClient.patch(`/api/api-keys/${apiKeyId}`, {
    enabled,
  })

  return result
}

export const fetchTenants = async (
  page: number,
  size: number,
  sortBy: string,
  nameFilter: string
): Promise<Page<Tenant>> => {
  const result = await hadesClient.get(`/api/tenants/v2`, {
    params: {
      page,
      size,
      sortBy,
      name: nameFilter,
    },
  })

  return result.data
}

export const fetchOAuthClients = async (
  page: number,
  size: number,
  sortBy: string,
  nameFilter: string
): Promise<Page<OAuthClient>> => {
  const result = await hadesClient.get(`/api/oauth/clients`, {
    params: {
      page,
      size,
      sortBy,
      name: nameFilter,
    },
  })

  return result.data
}

export const fetchPractitioners = async (page: number, size: number, nameFilter: string): Promise<Practitioner[]> => {
  const result = await hadesClient.get(`/api/practitioners`, {
    params: {
      page,
      size,
      name: nameFilter,
    },
  })

  return result.data
}

export const editTenant = async (
  tenantId: string,
  name: string | null,
  internal: boolean | null,
  deprecated: boolean | null,
  subscribedFor: string[] | null
) => {
  if (name == null && internal == null && deprecated == null && subscribedFor == null) {
    throw Error('At least one of name, internal or deprecated must be provided to perform a tenant modification.')
  }

  const result = await hadesClient.patch(`/api/tenants/${tenantId}`, {
    name: name,
    internal: internal,
    deprecated: deprecated,
    subscribedFor: subscribedFor,
  })

  return result
}

export const createTenant = async (name: string, internal: boolean, subscribedFor: string[]) => {
  const result = await hadesClient.post(`/api/tenants/`, {
    name,
    internal,
    subscribedFor,
  })

  return result
}

export const createOAuthClient = async (
  name: string,
  redirectUris?: string[],
  idTokenSignedResponseAlg?: string,
  allowedAudiences?: string[],
  defaultAudience?: string,
  systemUserReferenceId?: string
): Promise<OAuthClient> => {
  const result = await hadesClient.post('/api/oauth/clients', {
    name,
    redirectUris,
    idTokenSignedResponseAlg,
    allowedAudiences,
    defaultAudience,
    systemUserReferenceId,
  })

  return result.data
}

export const updateOAuthClient = async (
  clientId: string,
  name?: string,
  redirectUris?: string[],
  idTokenSignedResponseAlg?: string,
  allowedAudiences?: string[],
  defaultAudience?: string,
  systemUserReferenceId?: string
): Promise<OAuthClient> => {
  const result = await hadesClient.patch(`/api/oauth/clients/${clientId}`, {
    name,
    redirectUris,
    idTokenSignedResponseAlg,
    allowedAudiences,
    defaultAudience,
    systemUserReferenceId,
  })

  return result.data
}
