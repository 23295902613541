import React from 'react'
import {
  Alert,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
  Tooltip,
  Button,
} from '@mui/material'
import { ContentCopy } from '@mui/icons-material'
import { styles } from './styles'

interface Props {
  open: boolean
  clientSecret: string
  onClose: () => void
}

const OAuthClientCreateSuccess: React.FC<Props> = ({ open, clientSecret, onClose }) => {
  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(clientSecret)
    } catch (err) {
      console.error('Failed to copy:', err)
    }
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Client Created Successfully</DialogTitle>
      <DialogContent>
        <Alert severity="warning" sx={styles.alert}>
          This secret will not be shown again. Please save it securely.
        </Alert>
        <Box sx={styles.secretContainer}>
          <Typography fontFamily="monospace" sx={styles.secretText}>
            {clientSecret}
          </Typography>
          <Tooltip title="Copy to clipboard">
            <IconButton onClick={copyToClipboard} size="small">
              <ContentCopy />
            </IconButton>
          </Tooltip>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default OAuthClientCreateSuccess
