import React, { useState } from 'react'

import { StyledTableCell, StyledTableRow } from '../../../components/EnchancedTable'
import { createStyles, makeStyles } from '@mui/styles'
import { CircularProgress, IconButton, TextField, Theme } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import EditIcon from '@mui/icons-material/Edit'
import { Tenant } from '../../../types'
import EnabledSwtich from '../../../components/EnabledSwtich'
import { SubscriptionPicker } from './SubscriptionPicker'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(0.4),
      marginBottom: theme.spacing(0.7),
      marginTop: theme.spacing(0.7),
    },
  })
)

interface Props {
  tenant: Tenant
  saveEdit: (
    tenantId: string,
    name: string | null,
    internal: boolean | null,
    deprecated: boolean | null,
    subscribedFor: string[] | null
  ) => void
  loading: boolean
}

const TenantEditRow: React.FC<Props> = ({ tenant, saveEdit, loading }) => {
  const classes = useStyles()
  const [edit, setEdit] = useState(false)
  const [name, setName] = useState(tenant.name)
  const [internal, setInternal] = useState(tenant.internal)
  const [deprecated, setDeprecated] = useState(tenant.deprecated)
  const [subscribedFor, setSubscribedFor] = useState(tenant.subscribedFor)

  const toggleEdit = () => {
    setEdit(!edit)
  }

  const onCancel = () => {
    setName(tenant.name)
    setInternal(tenant.internal)
    setDeprecated(tenant.deprecated)
    toggleEdit()
  }

  const onSave = () => {
    saveEdit(
      tenant.referenceId,
      tenant.name === name ? null : name,
      tenant.internal === internal ? null : internal,
      tenant.deprecated === deprecated ? null : deprecated,
      tenant.subscribedFor.join(',') === subscribedFor.join(',') ? null : subscribedFor
    )
    toggleEdit()
  }

  const setActive = (active: boolean) => setDeprecated(!active)

  const onChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value)
  }

  return edit ? (
    <StyledTableRow hover role="checkbox" tabIndex={-1} key={`edit_row_${tenant.referenceId}`}>
      <StyledTableCell padding="checkbox" align="center">
        <IconButton size="small" onClick={onSave} color="primary">
          {loading ? <CircularProgress size={24} className={classes.icon} /> : <CheckIcon />}
        </IconButton>
        <IconButton size="small" onClick={onCancel}>
          <CloseIcon />
        </IconButton>
      </StyledTableCell>

      <StyledTableCell padding="checkbox" align="left"></StyledTableCell>

      <StyledTableCell align="left">
        <TextField value={name} onChange={onChangeName} />
      </StyledTableCell>

      <StyledTableCell align="right">
        {<EnabledSwtich enabled={internal} editable={true} onChange={setInternal} />}
      </StyledTableCell>
      <StyledTableCell align="right">
        {<EnabledSwtich enabled={!deprecated} editable={true} onChange={setActive} />}
      </StyledTableCell>
      <StyledTableCell>
        <SubscriptionPicker value={subscribedFor} onChange={setSubscribedFor} />
      </StyledTableCell>
    </StyledTableRow>
  ) : (
    <StyledTableRow hover role="checkbox" tabIndex={-1} key={`view_row_${tenant.referenceId}`}>
      <StyledTableCell padding="checkbox" align="left">
        <IconButton size="small" onClick={toggleEdit}>
          {loading ? <CircularProgress size={24} className={classes.icon} /> : <EditIcon />}
        </IconButton>
      </StyledTableCell>
      <StyledTableCell padding="checkbox" align="left"></StyledTableCell>
      <StyledTableCell align="left">{tenant.name}</StyledTableCell>

      <StyledTableCell align="right">
        {<EnabledSwtich enabled={internal} editable={false} onChange={setInternal} />}
      </StyledTableCell>
      <StyledTableCell align="right">
        {<EnabledSwtich enabled={!deprecated} editable={false} onChange={setActive} />}
      </StyledTableCell>
      <StyledTableCell>
        <SubscriptionPicker disabled={true} value={subscribedFor} onChange={setSubscribedFor} />
      </StyledTableCell>
    </StyledTableRow>
  )
}

export default TenantEditRow
