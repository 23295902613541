import { useState, useEffect, useMemo } from 'react'
import { Clinic, Role, SystemUser } from '../../../types'
import { fetchSystemUsers } from '../../../api/API'
import { useDebounce } from 'use-debounce'

interface UseSystemUsersProps {
  open: boolean
  page?: number
  pageSize?: number
  sortBy?: string
  nameFilter?: string
  clinicFilter?: Clinic[]
  roleFilter?: Role[]
}

interface UseSystemUsersReturn {
  systemUsers: SystemUser[]
  loadingUsers: boolean
}

export const useSystemUsers = ({
  open,
  page = 0,
  pageSize = 20,
  sortBy = 'name',
  nameFilter = '',
  clinicFilter = [],
  roleFilter = [],
}: UseSystemUsersProps): UseSystemUsersReturn => {
  const [systemUsers, setSystemUsers] = useState<SystemUser[]>([])
  const [loadingUsers, setLoadingUsers] = useState(false)

  // Memoize the clinic and role IDs
  const clinicIds = useMemo(() => clinicFilter.map((clinic) => clinic.referenceId), [clinicFilter])
  const roleIds = useMemo(() => roleFilter.map((role) => role.referenceId), [roleFilter])

  // Debounce the name filter
  const [debouncedNameFilter] = useDebounce(nameFilter, 300)

  useEffect(
    () => {
      if (!open) {
        setSystemUsers([])
        return
      }

      setLoadingUsers(true)
      fetchSystemUsers(page, pageSize, sortBy, debouncedNameFilter, clinicFilter, roleFilter)
        .then((response) => setSystemUsers(response.data))
        .finally(() => {
          setLoadingUsers(false)
        })
    },
    //dependencies are fine
    //eslint-disable-next-line
    [open, page, pageSize, sortBy, debouncedNameFilter, clinicIds.join(','), roleIds.join(',')]
  )

  return { systemUsers, loadingUsers }
}
