import React from 'react'
import {
  Select,
  MenuItem,
  OutlinedInput,
  Box,
  Chip,
  FormControl,
  InputLabel,
  Checkbox,
  ListItemText,
} from '@mui/material'
import { AVAILABLE_SUBSCRIPTIONS } from '../../../types'

interface SubscriptionPickerProps {
  value: string[]
  onChange: (value: string[]) => void
  disabled?: boolean
}

export const SubscriptionPicker: React.FC<SubscriptionPickerProps> = ({ value, onChange, disabled = false }) => {
  const handleChange = (event: any) => {
    const selectedValue = event.target.value
    onChange(typeof selectedValue === 'string' ? selectedValue.split(',') : selectedValue)
  }

  return (
    <FormControl fullWidth margin="dense">
      <InputLabel id="subscription-label">Subscriptions</InputLabel>
      <Select
        labelId="subscription-label"
        id="subscription-select"
        multiple
        value={value}
        onChange={handleChange}
        input={<OutlinedInput label="Subscriptions" />}
        disabled={disabled}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value} label={value} />
            ))}
          </Box>
        )}
      >
        {AVAILABLE_SUBSCRIPTIONS.map((subscription) => (
          <MenuItem key={subscription} value={subscription}>
            <Checkbox checked={value.indexOf(subscription) > -1} />
            <ListItemText primary={subscription} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
