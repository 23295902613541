import { SxProps, Theme } from '@mui/material'

export const styles = {
  secretContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    backgroundColor: (theme: Theme) => theme.palette.grey[100],
    p: 2,
    borderRadius: 1,
    mb: 2,
    wordBreak: 'break-all',
  } as SxProps<Theme>,

  secretText: {
    flex: 1,
  } as SxProps<Theme>,

  alert: {
    mb: 2,
  } as SxProps<Theme>,
}
