import React, { useState, ChangeEvent, useEffect } from 'react'
import {
  Alert,
  AlertTitle,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  Autocomplete,
} from '@mui/material'
import { useSystemUsers } from './useSystemUsersHook'
import { SystemUser } from '../../../types'

export interface CreateOAuthClientCmd {
  name: string
  redirectUris?: string[]
  idTokenSignedResponseAlg?: string
  allowedAudiences?: string[]
  defaultAudience?: string
  systemUserReferenceId?: string
}

interface Props {
  open: boolean
  loading: boolean
  error: string | null
  onCancel: () => void
  onCreate: (cmd: CreateOAuthClientCmd) => void
}

const initialState = {
  name: '',
  redirectUris: '',
  idTokenSignedResponseAlg: null as string | null,
  allowedAudiences: '',
  defaultAudience: '',
  errors: {
    redirectUris: '',
    defaultAudience: '',
  },
  systemUserReferenceId: '',
}

const isValidUri = (uri: string): boolean => {
  try {
    new URL(uri)
    return true
  } catch {
    return false
  }
}

const OAuthCreator: React.FC<Props> = ({ open, loading, error, onCancel, onCreate }) => {
  const [formState, setFormState] = useState(initialState)
  const { systemUsers, loadingUsers } = useSystemUsers({ open })

  useEffect(() => {
    if (!open) {
      setFormState(initialState)
    }
  }, [open])

  const validateUris = (uris: string): string => {
    const invalidUris = uris
      .split('\n')
      .filter((uri) => uri.trim() !== '')
      .filter((uri) => !isValidUri(uri))

    return invalidUris.length > 0 ? `Invalid URIs: ${invalidUris.join(', ')}` : ''
  }

  const validateDefaultAudience = (audience: string, allowedAudiences: string): string => {
    if (!audience) return ''

    const audiences = allowedAudiences.split('\n').filter((a) => a.trim() !== '')
    return audiences.includes(audience) ? '' : 'Default audience must be included in allowed audiences'
  }

  const handleChange = (field: keyof typeof initialState) => (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setFormState((prev) => {
      const newState = {
        ...prev,
        [field]: value,
        errors: { ...prev.errors },
      }

      if (field === 'redirectUris') {
        newState.errors.redirectUris = validateUris(value)
      }

      if (field === 'allowedAudiences' || field === 'defaultAudience') {
        newState.errors.defaultAudience = validateDefaultAudience(
          field === 'defaultAudience' ? value : prev.defaultAudience,
          field === 'allowedAudiences' ? value : prev.allowedAudiences
        )
      }

      return newState
    })
  }

  const handleAlgorithmChange = (event: SelectChangeEvent<string>) => {
    setFormState((prev) => ({
      ...prev,
      idTokenSignedResponseAlg: event.target.value as string | null,
    }))
  }

  const handleSystemUserChange = (_event: any, value: SystemUser | null) => {
    setFormState((prev) => ({
      ...prev,
      systemUserReferenceId: value?.referenceId || '',
    }))
  }

  const isValid = () => {
    const hasRedirectUris = formState.redirectUris.split('\n').some((uri) => uri.trim() !== '')
    const hasAllowedAudiences = formState.allowedAudiences.split('\n').some((uri) => uri.trim() !== '')
    const hasNoErrors = !Object.values(formState.errors).some((error) => error)
    return formState.name && (hasRedirectUris || hasAllowedAudiences) && hasNoErrors
  }

  const submit = () => {
    onCreate({
      name: formState.name,
      redirectUris: formState?.redirectUris.split('\n').filter((uri) => uri.trim() !== ''),
      idTokenSignedResponseAlg: formState.idTokenSignedResponseAlg || undefined,
      allowedAudiences: formState.allowedAudiences?.split('\n').filter((uri) => uri.trim() !== ''),
      defaultAudience: formState.defaultAudience || undefined,
      systemUserReferenceId: formState.systemUserReferenceId || undefined,
    })
  }

  return (
    <Dialog onClose={onCancel} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">Create new OAuth Client</DialogTitle>
      <DialogContent>
        <DialogContentText>Please fill in the required data to create a new OAuth client.</DialogContentText>
        {error && (
          <Alert severity="error">
            <AlertTitle>Error creating new OAuth client</AlertTitle>
            <p style={{ whiteSpace: 'pre-wrap' }}>{error}</p>
          </Alert>
        )}
        <TextField
          value={formState.name}
          onChange={handleChange('name')}
          autoFocus
          margin="dense"
          id="name"
          label="Name"
          type="text"
          disabled={loading}
          fullWidth
          required
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          value={formState.redirectUris}
          onChange={handleChange('redirectUris')}
          margin="dense"
          id="redirectUris"
          label="Redirect URIs (one per line)"
          multiline
          rows={3}
          disabled={loading}
          fullWidth
          required
          error={!!formState.errors.redirectUris}
          helperText={formState.errors.redirectUris || 'At least one Redirect URI or Allowed Audience is required'}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <FormControl fullWidth margin="dense">
          <InputLabel id="algorithm-label">ID Token Signed Response Algorithm</InputLabel>
          <Select
            labelId="algorithm-label"
            value={formState.idTokenSignedResponseAlg || ''}
            onChange={handleAlgorithmChange}
            disabled={loading}
            fullWidth
          >
            <MenuItem value="">None</MenuItem>
            <MenuItem value="RS256">RS256</MenuItem>
            <MenuItem value="ES256">ES256</MenuItem>
          </Select>
        </FormControl>
        <Autocomplete
          options={systemUsers}
          getOptionLabel={(option) => option.name}
          onChange={handleSystemUserChange}
          loading={loadingUsers}
          renderInput={(params) => (
            <TextField
              {...params}
              margin="dense"
              label="System User"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loadingUsers ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
        <TextField
          value={formState.allowedAudiences}
          onChange={handleChange('allowedAudiences')}
          margin="dense"
          id="allowedAudiences"
          label="Allowed Audiences (one per line)"
          multiline
          rows={3}
          disabled={loading}
          fullWidth
          required
          InputLabelProps={{
            shrink: true,
          }}
          helperText="At least one Redirect URI or Allowed Audience is required"
        />
        <TextField
          value={formState.defaultAudience}
          onChange={handleChange('defaultAudience')}
          margin="dense"
          id="defaultAudience"
          label="Default Audience"
          type="text"
          disabled={loading}
          fullWidth
          error={!!formState.errors.defaultAudience}
          helperText={formState.errors.defaultAudience}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary" disabled={loading}>
          Cancel
        </Button>
        <Button onClick={submit} color="primary" disabled={loading || !isValid()}>
          {!loading ? 'Create' : <CircularProgress color="primary" size="1.2em" />}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default OAuthCreator
