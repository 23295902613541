import React, { useState, ChangeEvent, useEffect } from 'react'
import {
  Alert,
  AlertTitle,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  Autocomplete,
} from '@mui/material'
import { OAuthClient, SystemUser } from '../../../types'
import { useSystemUsers } from './useSystemUsersHook'

export interface UpdateOAuthClientCmd {
  clientId: string
  name?: string
  redirectUris?: string[]
  idTokenSignedResponseAlg?: string
  allowedAudiences?: string[]
  defaultAudience?: string
  systemUserReferenceId?: string
}

interface Props {
  open: boolean
  loading: boolean
  error: string | null
  client: OAuthClient | null
  onCancel: () => void
  onUpdate: (cmd: UpdateOAuthClientCmd) => void
}

interface FormState {
  values: {
    name: string
    redirectUris: string
    idTokenSignedResponseAlg: string | null
    allowedAudiences: string
    defaultAudience: string
    systemUserReferenceId: string
  }
  initialValues: {
    name: string
    redirectUris: string
    idTokenSignedResponseAlg: string | null
    allowedAudiences: string
    defaultAudience: string
    systemUserReferenceId: string
  }
  errors: {
    redirectUris: string
    defaultAudience: string
  }
}

const createInitialState = (client: Props['client']): FormState => {
  const values = {
    name: client?.name || '',
    redirectUris: Array.from(client?.redirectUris || []).join('\n'),
    idTokenSignedResponseAlg: client?.idTokenSignedResponseAlg || '',
    allowedAudiences: Array.from(client?.allowedAudiences || []).join('\n'),
    defaultAudience: client?.defaultAudience || '',
    systemUserReferenceId: client?.user?.referenceId || '',
  }

  return {
    values: { ...values },
    initialValues: { ...values },
    errors: {
      redirectUris: '',
      defaultAudience: '',
    },
  }
}

const isValidUri = (uri: string): boolean => {
  try {
    new URL(uri)
    return true
  } catch {
    return false
  }
}

const OAuthClientEditor: React.FC<Props> = ({ open, loading, error, client, onCancel, onUpdate }) => {
  const [formState, setFormState] = useState<FormState>(createInitialState(client))
  const { systemUsers, loadingUsers } = useSystemUsers({ open })

  useEffect(() => {
    setFormState(createInitialState(client))
  }, [open, client])

  const validateUris = (uris: string): string => {
    const invalidUris = uris
      .split('\n')
      .filter((uri) => uri.trim() !== '')
      .filter((uri) => !isValidUri(uri))

    return invalidUris.length > 0 ? `Invalid URIs: ${invalidUris.join(', ')}` : ''
  }

  const validateDefaultAudience = (audience: string, allowedAudiences: string): string => {
    if (!audience) return ''

    const audiences = allowedAudiences.split('\n').filter((a) => a.trim() !== '')
    return audiences.includes(audience) ? '' : 'Default audience must be included in allowed audiences'
  }

  const handleChange = (field: keyof FormState['values']) => (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setFormState((prev) => {
      const newState = {
        ...prev,
        values: {
          ...prev.values,
          [field]: value,
        },
        errors: { ...prev.errors },
      }

      if (field === 'redirectUris') {
        newState.errors.redirectUris = validateUris(value)
      }

      if (field === 'allowedAudiences' || field === 'defaultAudience') {
        newState.errors.defaultAudience = validateDefaultAudience(
          field === 'defaultAudience' ? value : prev.values.defaultAudience,
          field === 'allowedAudiences' ? value : prev.values.allowedAudiences
        )
      }

      return newState
    })
  }

  const handleAlgorithmChange = (event: SelectChangeEvent<string>) => {
    setFormState((prev) => ({
      ...prev,
      values: {
        ...prev.values,
        idTokenSignedResponseAlg: event.target.value as string | null,
      },
    }))
  }

  const handleSystemUserChange = (_event: any, value: SystemUser | null) => {
    setFormState((prev) => ({
      ...prev,
      values: {
        ...prev.values,
        systemUserReferenceId: value?.referenceId || '',
      },
    }))
  }

  const getModifiedFields = () => {
    const modified: Partial<UpdateOAuthClientCmd> = {}
    const { values, initialValues } = formState

    if (values.name !== initialValues.name) {
      modified.name = values.name
    }
    if (values.redirectUris !== initialValues.redirectUris) {
      modified.redirectUris = values.redirectUris.split('\n').filter((uri) => uri.trim() !== '')
    }
    if (values.idTokenSignedResponseAlg !== initialValues.idTokenSignedResponseAlg) {
      modified.idTokenSignedResponseAlg = values.idTokenSignedResponseAlg || undefined
    }
    if (values.allowedAudiences !== initialValues.allowedAudiences) {
      modified.allowedAudiences = values.allowedAudiences.split('\n').filter((uri) => uri.trim() !== '')
    }
    if (values.defaultAudience !== initialValues.defaultAudience) {
      modified.defaultAudience = values.defaultAudience || undefined
    }
    if (values.systemUserReferenceId !== initialValues.systemUserReferenceId) {
      modified.systemUserReferenceId = values.systemUserReferenceId || undefined
    }

    return modified
  }

  const isValid = () => {
    const hasNoErrors = !Object.values(formState.errors).some((error) => error)
    const hasModifications = Object.keys(getModifiedFields()).length > 0
    return hasNoErrors && hasModifications
  }

  const submit = () => {
    const modifiedFields = getModifiedFields()
    onUpdate({
      clientId: client?.clientId || '',
      ...modifiedFields,
    })
  }

  // Find the current system user object for the Autocomplete
  const currentSystemUser =
    systemUsers.find((user) => user.referenceId === formState.values.systemUserReferenceId) || null

  return (
    <Dialog onClose={onCancel} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">Edit OAuth Client</DialogTitle>
      <DialogContent>
        <DialogContentText>Modify the fields you want to update.</DialogContentText>
        {error && (
          <Alert severity="error">
            <AlertTitle>Error updating OAuth client</AlertTitle>
            <p style={{ whiteSpace: 'pre-wrap' }}>{error}</p>
          </Alert>
        )}
        <TextField
          value={formState.values.name}
          onChange={handleChange('name')}
          margin="dense"
          id="name"
          label="Name"
          type="text"
          disabled={loading}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          value={formState.values.redirectUris}
          onChange={handleChange('redirectUris')}
          margin="dense"
          id="redirectUris"
          label="Redirect URIs (one per line)"
          multiline
          rows={3}
          disabled={loading}
          fullWidth
          error={!!formState.errors.redirectUris}
          helperText={formState.errors.redirectUris}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <FormControl fullWidth margin="dense">
          <InputLabel id="algorithm-label">ID Token Signed Response Algorithm</InputLabel>
          <Select
            labelId="algorithm-label"
            value={formState.values.idTokenSignedResponseAlg || ''}
            onChange={handleAlgorithmChange}
            disabled={loading}
            fullWidth
          >
            <MenuItem value="">None</MenuItem>
            <MenuItem value="RS256">RS256</MenuItem>
            <MenuItem value="ES256">ES256</MenuItem>
          </Select>
        </FormControl>
        <Autocomplete
          value={currentSystemUser}
          options={systemUsers}
          getOptionLabel={(option) => option.name}
          onChange={handleSystemUserChange}
          loading={loadingUsers}
          disabled={loading}
          renderInput={(params) => (
            <TextField
              {...params}
              margin="dense"
              label="System User"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loadingUsers ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
        <TextField
          value={formState.values.allowedAudiences}
          onChange={handleChange('allowedAudiences')}
          margin="dense"
          id="allowedAudiences"
          label="Allowed Audiences (one per line)"
          multiline
          rows={3}
          disabled={loading}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          value={formState.values.defaultAudience}
          onChange={handleChange('defaultAudience')}
          margin="dense"
          id="defaultAudience"
          label="Default Audience"
          type="text"
          disabled={loading}
          fullWidth
          error={!!formState.errors.defaultAudience}
          helperText={formState.errors.defaultAudience}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary" disabled={loading}>
          Cancel
        </Button>
        <Button onClick={submit} color="primary" disabled={loading || !isValid()}>
          {!loading ? 'Update' : <CircularProgress color="primary" size="1.2em" />}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default OAuthClientEditor
