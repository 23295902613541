import React, { useState, ChangeEvent } from 'react'
import {
  Alert,
  AlertTitle,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  TextField,
  Select,
  MenuItem,
  OutlinedInput,
  Box,
  Chip,
  FormControl,
  InputLabel,
} from '@mui/material'
import EnabledSwtich from '../../../components/EnabledSwtich'
import { AVAILABLE_SUBSCRIPTIONS } from '../../../types'

export interface CreateTenantCmd {
  name: string
  internal: boolean
  subscribedFor: string[]
}

interface Props {
  open: boolean
  loading: boolean
  error: string | null
  onCancel: () => void
  onCreate: (cmd: CreateTenantCmd) => void
}

const TenantCreator: React.FC<Props> = ({ open, loading, error, onCancel, onCreate }) => {
  const [name, setName] = useState('')
  const [internal, setInternal] = useState(false)
  const [subscribedFor, setSubscribedFor] = useState<string[]>([])

  const cancel = () => {
    onCancel()
  }

  const submit = () => {
    onCreate({
      name,
      internal,
      subscribedFor,
    })
  }

  const onChangeName = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setName(event.target.value)
  }

  const handleSubscriptionChange = (event: any) => {
    const {
      target: { value },
    } = event
    setSubscribedFor(typeof value === 'string' ? value.split(',') : value)
  }

  return (
    <Dialog onClose={cancel} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">Create new tenant</DialogTitle>
      <DialogContent>
        <DialogContentText>Please fill in the required data in order to create a new tenant.</DialogContentText>
        {error && (
          <Alert severity="error">
            <AlertTitle>Error creating new tenant</AlertTitle>
            <p style={{ whiteSpace: 'pre-wrap' }}>{error}</p>
          </Alert>
        )}
        <TextField
          value={name}
          onChange={onChangeName}
          autoFocus
          margin="dense"
          id="name"
          label="Name"
          type="text"
          disabled={loading}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
        <FormControl fullWidth margin="dense">
          <InputLabel id="subscription-label">Subscriptions</InputLabel>
          <Select
            labelId="subscription-label"
            id="subscription-select"
            multiple
            value={subscribedFor}
            onChange={handleSubscriptionChange}
            input={<OutlinedInput label="Subscriptions" />}
            disabled={loading}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip key={value} label={value} />
                ))}
              </Box>
            )}
          >
            {AVAILABLE_SUBSCRIPTIONS.map((subscription) => (
              <MenuItem key={subscription} value={subscription}>
                {subscription}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControlLabel
          control={<EnabledSwtich enabled={internal} editable={true} onChange={setInternal} />}
          label="Is Patient21"
          labelPlacement="start"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={cancel} color="primary" disabled={loading}>
          Cancel
        </Button>
        <Button onClick={submit} color="primary" disabled={loading}>
          {!loading ? 'Create' : <CircularProgress color="primary" size="1.2em" />}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default TenantCreator
