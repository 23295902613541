import React, { useState } from 'react'
import { Switch, Link, useLocation } from 'react-router-dom'

import AssignmentIndIcon from '@mui/icons-material/AssignmentInd'
import SecurityIcon from '@mui/icons-material/Security'
import RecentActorsIcon from '@mui/icons-material/RecentActors'
import MemoryIcon from '@mui/icons-material/Memory'
import AppsIcon from '@mui/icons-material/Apps'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import OAuthIcon from './components/OAuthIcon'

import clsx from 'clsx'

import NavBar from './components/NavBar'
import Users from './pages/User/Users'
import Roles from './pages/Roles'
import { View } from './types'
import { ProtectedRoute } from '@dentalux/security/lib'
import Applications from './pages/Applications'
import UserChanges from './pages/UserChanges/UserChanges'
import SystemUsers from './pages/SystemUser/SystemUser'
import { createStyles, makeStyles } from '@mui/styles'
import { Box, Drawer, Tab, Tabs, Theme, Tooltip, Typography, Zoom } from '@mui/material'
import Tenants from './pages/Tenant/Tenants'
import OAuth from './pages/OAuth/OAuth'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      flexGrow: 1,
      padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    },
    contentShrink: {
      marginLeft: 80,
    },
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.text.primary,
      boxShadow: theme.shadows[1],
      fontSize: theme.typography.fontSize,
    },
  })
)

interface MenuTooltipProps {
  text: string
  children: React.ReactElement<any, any>
}

const MenuTooltip: React.FC<MenuTooltipProps> = ({ text, children }) => {
  const classes = useStyles()
  return (
    <Tooltip title={text} placement="right" TransitionComponent={Zoom} classes={{ tooltip: classes.tooltip }}>
      {children}
    </Tooltip>
  )
}

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={1}>{children}</Box>}
    </Typography>
  )
}

const menuItems = ['/', '/changes', '/system-users', '/tenants', '/oauth', '/roles', '/applications']

const Main = () => {
  const classes = useStyles()
  const location = useLocation()
  const [open, setOpen] = useState(true)
  const [navItems] = useState<React.ReactNode>(null)

  const handleMenuClose = () => {
    setOpen(!open)
  }

  return (
    <div>
      <NavBar handleMenuClose={handleMenuClose} navItems={navItems} />
      <Drawer
        variant="persistent"
        sx={{
          width: 80,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: 80, boxSizing: 'border-box', top: 'inherit' },
        }}
        elevation={2}
        open={open}
      >
        <Tabs
          orientation="vertical"
          variant="scrollable"
          scrollButtons={false}
          aria-label="side navigation"
          value={menuItems.includes(location.pathname) ? location.pathname : false}
          textColor="primary"
        >
          <Tab
            value={'/'}
            label=""
            icon={
              <MenuTooltip text="Users">
                <AssignmentIndIcon />
              </MenuTooltip>
            }
            to="/"
            component={Link}
            sx={{ minWidth: '80px' }}
          />
          <Tab
            value={'/changes'}
            label=""
            icon={
              <MenuTooltip text="User changes">
                <RecentActorsIcon />
              </MenuTooltip>
            }
            to="/changes"
            component={Link}
            sx={{ minWidth: '80px' }}
          />
          <Tab
            value={'/system-users'}
            label=""
            icon={
              <MenuTooltip text="System users">
                <MemoryIcon />
              </MenuTooltip>
            }
            to="/system-users"
            component={Link}
            sx={{ minWidth: '80px' }}
          />
          <Tab
            value={'/tenants'}
            label=""
            icon={
              <MenuTooltip text="Tenants">
                <AccountBalanceIcon />
              </MenuTooltip>
            }
            to="/tenants"
            component={Link}
            sx={{ minWidth: '80px' }}
          />
          <Tab
            value={'/oauth'}
            label=""
            icon={
              <MenuTooltip text="OAuth clients">
                <OAuthIcon />
              </MenuTooltip>
            }
            to="/oauth"
            component={Link}
            sx={{ minWidth: '80px' }}
          />
          <Tab
            value={'/roles'}
            label=""
            icon={
              <MenuTooltip text="Data permissions">
                <SecurityIcon />
              </MenuTooltip>
            }
            to="/roles"
            component={Link}
            sx={{ minWidth: '80px' }}
          />

          <Tab
            value={'/applications'}
            label=""
            icon={
              <MenuTooltip text="Application permissions">
                <AppsIcon />
              </MenuTooltip>
            }
            to="/applications"
            component={Link}
            sx={{ minWidth: '80px' }}
          />
        </Tabs>
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShrink]: open,
        })}
      >
        <TabPanel value={location.pathname} index={location.pathname}>
          <Switch>
            <ProtectedRoute path="/" exact view={View.USER_MANAGEMENT}>
              <Users />
            </ProtectedRoute>

            <ProtectedRoute path="/changes" exact view={View.USER_MANAGEMENT}>
              <UserChanges />
            </ProtectedRoute>

            <ProtectedRoute path="/system-users" exact view={View.USER_MANAGEMENT}>
              <SystemUsers />
            </ProtectedRoute>

            <ProtectedRoute path="/tenants" exact view={View.USER_MANAGEMENT}>
              <Tenants />
            </ProtectedRoute>

            <ProtectedRoute path="/oauth" exact view={View.ROLE_MANAGEMENT}>
              <OAuth />
            </ProtectedRoute>

            <ProtectedRoute path="/roles" exact view={View.ROLE_MANAGEMENT}>
              <Roles />
            </ProtectedRoute>

            <ProtectedRoute path="/applications" exact view={View.ROLE_MANAGEMENT}>
              <Applications />
            </ProtectedRoute>
          </Switch>
        </TabPanel>
      </main>
    </div>
  )
}

export default Main
